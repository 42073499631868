// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-components-page-js": () => import("/opt/build/repo/src/components/page.js" /* webpackChunkName: "component---src-components-page-js" */),
  "component---src-components-chapter-js": () => import("/opt/build/repo/src/components/chapter.js" /* webpackChunkName: "component---src-components-chapter-js" */),
  "component---src-pages-404-js": () => import("/opt/build/repo/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("/opt/build/repo/src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-index-js": () => import("/opt/build/repo/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-table-of-contents-js": () => import("/opt/build/repo/src/pages/table-of-contents.js" /* webpackChunkName: "component---src-pages-table-of-contents-js" */),
  "component---src-pages-thanks-js": () => import("/opt/build/repo/src/pages/thanks.js" /* webpackChunkName: "component---src-pages-thanks-js" */)
}

exports.data = () => import("/opt/build/repo/.cache/data.json")

